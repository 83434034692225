<template>
  <div class="card dashboard_common_panel">
    <div
      style="
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        z-index: 999;
        background-color: rgba(22, 29, 49, 0.63);
        opacity: 1;
        margin-left: -1.5rem;
      "
      v-show="loader"
    >
      <img
        :src="require('./loader.svg')"
        style="margin-left: 43%; margin-top: 23%"
      />
    </div>
    <div class="dashboard_common_header">
      <div class="back-custom" @click="backMain()">
        <span class="brand-logo" style="cursor: pointer">
          <b-img
            src="../../../../public/tdt_white.svg"
            style="width: 12rem; background-color: #2c2c2c; margin-top: 0.2rem"
            alt="logo"
          />
        </span>
      </div>
      <!-- <div class="seoul-logo" @click="()" > -->
      <div class="seoul-logo">
        <!-- <span class="brand-logo" style="cursor: Default;">
          <b-img
            src="../../../../public/resources/img/icons/seoul_corporation_logo.svg"
            style="width:50px; background-color: #2c2c2c; "
            alt="logo"
          />
        </span> -->
        <!-- <span style="color: white; font-size: 2rem;"> 3MIX {{ selectTunnelName }} </span> -->
      </div>
      <div>
        <div class="current-time" align-v="center">
          <span class="time">{{ currentDate }} {{ currentTime }}</span>
        </div>
        <div class="setting-button">
          <feather-icon
            v-b-modal.modal-scrollable
            v-if="true"
            icon="SettingsIcon"
            size="22"
            :darkCheck="true"
            @click="settingBarRefresh"
          />
          <SettingBar :key="settingBarKey"></SettingBar>
        </div>
      </div>
    </div>
    <!-- <div class="vertical-line"></div> -->
    <div class="card-body dashboard_common_body">
      <b-container fluid class="layout_table">
        <b-row class="text-center" style="width: 100%; height: 9%">
          <div style="width: 100%; height: 100%">
            <StateBar></StateBar>
          </div>
        </b-row>
        <!-- <b-row style="height: 9%;">
          <div style="width: 99%;height: 100%;">
            <div style="width: 27%;height: 70%;margin-top: 20px;background: linear-gradient(to right, #B51BE4, #FA71A3);border-radius: 40px;display: inline-block;">
              <div style="margin: 0.2vh 0.1vw; padding: 2.65vh 0.5vw; width: 99.3%; display: block; background: rgb(29, 34, 51);border-radius: 40px;"></div>
            </div>
          </div>
          <div style="width: 1%;">
            <div style="height: 100%; width: 5%; cursor: pointer;">
              <img src="resources/img/icons/dashboard/chart.svg"/>
            </div>
          </div>
        </b-row>  -->
        <!-- @click="$bvModal.show('chart_modal')" -->
        <!-- <b-row class="text-left" style="width: 100%; height: 91%; display: inline-flex;
																				background-image: url('resources/img/namsan_graphic.png');
																				background-repeat: no-repeat;
																				background-position-x: right;
																				background-position-y: bottom;
																				background-size: contain;"> -->
        <b-row
          class="text-left"
          style="
            width: 100%;
            height: 91%;
            display: inline-flex;
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: bottom;
            background-size: contain;
          "
        >
          <b-row style="height: 10%; width: 100%">
            <div style="display: flex; flex: 1">
              <div
                class="square"
                style="margin-right: 0px; margin-left: 0px"
                v-if="$i18n.locale == 'ko'"
              >
                <div class="circle-container">
                  <div class="circle" style="background-color: #e64359"></div>
                  <div class="text text_red">
                    ●{{ stagnation }}km/h{{ $t("label.L0033") }}
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #ff8e43"></div>
                  <div class="text text_orange">
                    ●{{ delay }}km/h{{ $t("label.L0033") }}
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #ffb431"></div>
                  <div class="text text_yellow">
                    ●{{ slow }}km/h{{ $t("label.L0033") }}
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #2ce999"></div>
                  <div class="text text_green">
                    ●{{ smooth }}km/h{{ $t("label.L0033") }}
                  </div>
                </div>
              </div>
              <div
                class="square"
                style="margin-right: 0px; margin-left: 0px"
                v-else
              >
                <div class="circle-container">
                  <div class="circle" style="background-color: #e64359"></div>
                  <div class="text text_red">
                    ●{{ $t("label.L0033") }} {{ stagnation }}km/h
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #ff8e43"></div>
                  <div class="text text_orange">
                    ●{{ $t("label.L0033") }} {{ delay }}km/h
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #ffb431"></div>
                  <div class="text text_yellow">
                    ●{{ $t("label.L0033") }} {{ slow }}km/h
                  </div>
                </div>
                <div class="circle-container">
                  <div class="circle" style="background-color: #2ce999"></div>
                  <div class="text text_green">
                    ●{{ $t("label.L0033") }} {{ smooth }}km/h
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="display: flex; flex: 1;">
              <div style="display: flex; flex: 0.73;">
                <div style="display: flex; flex: 1;">
                  <VDSTrafficInfo :linkCode="'LNK-0450-MCT'" style="justify-content: flex-start; align-items: flex-end; padding-right: 3%;"></VDSTrafficInfo>
                </div>
                <div style="display: flex; flex: 1;"> 
                  <VDSTrafficInfo :linkCode="'LNK-0450-CMT'" style="justify-content: flex-end; align-items: flex-end; padding-left: 2.6%;"></VDSTrafficInfo>
                </div>
              </div>
              <div style="display: flex; flex: 0.27;"></div>
            </div> -->
            <!-- <Effect></Effect> -->
            <div style="display: flex; flex: 1; justify-content: flex-end">
              <div class="demoBtn">
                <!-- <div style="height: 1.5vw; width: 1.5vw; border-radius: 20px; background: #2c2c2c;
                            cursor: pointer; display: flex; align-items: center; margin: auto;
                            margin-top: 3.2vw; box-shadow:0px 3px 6px #00000052" @click="$bvModal.show('chart_modal')">
                  <img src="resources/img/icons/dashboard/chart.svg" style="margin: auto;  width: 0.6vw;">
                </div>
                <ChartView :i_link_code="tunnel_code"></ChartView>  -->
                <!-- <b-button style="height: auto;" varient="tabColor" size="sm" @click="showDemoPopup()">EVENT</b-button>
                <b-button style="height: auto; margin-left: 5px; margin-right: 5px" size="sm" @click="playDemoBriefing()">BRIEFING</b-button>
                <feather-icon
                  icon="MicIcon"
                  size="22"
                  style="margin-right: 5px"
                  :darkCheck="true"
                /> -->
                <!-- <b-button
                  style="height: auto"
                  varient="tabColor"
                  size="sm"
                  @click="callLLaMon()"
                  >Call LLaMon</b-button
                > -->

                <!-- 녹음 아이콘 -->
                <feather-icon
                  icon="MicIcon"
                  size="22"
                  :class="{ 'recording-icon': isRecording }"
                  :style="{
                    color: isRecording ? 'red' : 'inherit',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }"
                  :darkCheck="true"
                  @click="toggleRecognition"
                />
                <!-- <p><strong>Recognized Text:</strong> {{ transcript }}</p>
                <p v-if="isListening">Listening...</p> -->
              </div>
            </div>
          </b-row>
          <b-row style="height: 5%; width: 100%; display: flex; z-index: 1">
            <div style="flex: 1"></div>
            <div
              style="
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-items: flex-end;
              "
            ></div>
          </b-row>
          <b-row style="height: 46%; width: 100%">
            <RealTimeDrivingRadar></RealTimeDrivingRadar>
          </b-row>
          <div class="alarm-container" v-show="openModal">
            <Alarm @open-modal="openPopup"></Alarm>
            <!-- <newAlarm @open-modal="openPopup"></newAlarm> -->
          </div>
          <!-- <b-row style="height: 2%; width: 100%; display: flex; z-index:1;"> -->
          <b-row style="height: 2%; width: 30%; display: flex; z-index: 1">
            <div style="flex: 1"></div>
            <div
              style="
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-items: flex-end;
              "
            ></div>
          </b-row>
          <b-row style="height: 34%; width: 100%">
            <div style="width: 71%; height: 100%">
              <RealTimeCctvRadar></RealTimeCctvRadar>
            </div>
            <div style="width: 1%"></div>
            <div style="width: 28%; height: 100%">
              <EventTable></EventTable>
            </div>
          </b-row>
        </b-row>
      </b-container>
    </div>
    <div>
      <EventDemoPopup
        ref="eventDemoPop"
        :propTitle="$t('label.L0002')"
        :darkCheck="true"
      ></EventDemoPopup>
    </div>
  </div>
</template>

<script>
import { BContainer, BRow, BImg, BButton } from "bootstrap-vue";
// import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
// import { faHome, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import EventBus from "@/event-bus";
import EventDemoPopup from "../event/components/eventDemoPopup.vue";

import StateBar from "./components/State";
// import RecordData from './Dashboard/RecordData';
import RealTimeDrivingRadar from "./components/RealTimeDrivingRadar";
import RealTimeCctvRadar from "./components/RealTimeCctvRadar";
import Alarm from "./components/Alarm";
import newAlarm from "./components/newAlarm";
// import ChartView from './Dashboard/Alarm/ChartView';
import EventTable from "./components/EventTable";
import Effect from "./components/Effect";
import SettingBar from "./components/Setting";
// import VDSTrafficInfo from './Dashboard/VDSTrafficInfo'
import axios from "axios";

export default {
  components: {
    BContainer,
    BRow,
    BImg,
    BButton,
    StateBar,
    // RecordData,
    RealTimeDrivingRadar,
    RealTimeCctvRadar,
    Alarm,
    newAlarm,
    // ChartView,
    EventTable,
    Effect,
    SettingBar,
    EventDemoPopup,
    // VDSTrafficInfo
  },
  data: function () {
    return {
      tunnel_code: "TDT",
      openModal: false, // true 일때만 알람창 보임
      currentDate: "",
      currentTime: "",
      timer: null,
      stagnation: 0,
      delay: 0,
      slow: 0,
      smooth: 0,
      tunnelName:
        this.$store.getters["settingConfig/getTarget"]("gs").direction
          .tunnelName,
      settingBarKey: 0,
      selectTunnelName: null,
      timerVersion: null,
      popupCnt: 0,
      bEventDemoData: false,

      transcript: "", // 음성으로 인식된 텍스트
      recognition: null, // SpeechRecognition 인스턴스
      isListening: false, // 현재 음성 인식 중인지 여부
      isRecording: false, // 녹음 중인지 여부 (버튼 색상 변경에 사용)
      timeoutId: null, // 타이머 ID 저장
      loader: false,
      llamonCall: false,
    };
  },
  methods: {
    async callLLaMon() {
      try {
        const payload = { text: "show briefing yesterday" };
        const response = await axios.post(
          "http://10.1.1.62:3327/analyze_text",
          payload
        );
        console.log("응답 데이터:", response.data);
      } catch (err) {
        console.error("POST 요청 중 오류가 발생했습니다.", err);
      }
    },
    async showDemoPopup() {
      try {
        this.popupCnt++;
        let data = {
          popupCnt: this.popupCnt,
          alertDescription: null,
          alertLevel: 0,
          bound: 1,
          cameraId: "CCTV7",
          confirmId: null,
          confirmType: 1,
          confirmed: false,
          dEventType: 1,
          direction: "up",
          distance: 0,
          eLatitude: 0,
          eLongitude: 0,
          elevation: 0,
          equipId: null,
          equipName: null,
          eventDirect: "Upstream",
          eventEquip: 2,
          eventIcon: "01",
          eventRealTime: "14:54:44",
          eventType: "Stopped Vehicle",
          linkCode: "LNK-0450S-SBT",
          location: null,
          memo: "",
          name: "CCTV7",
          no: 50950,
          occurTime: "",
          position: 1.18,
          rEventType: 1,
          recImageUrl: null,
          recVideoUrl: "demoEventTest",
          tech: "C",
          vehicleCount: 0,
        };
        if (this.popupCnt == 3) {
          this.popupCnt = 0;
        }
        this.$refs.eventDemoPop.updatepopup(data);
      } catch (err) {
        console.log("EventDemoPopup msg : ", err);
      }
    },

    playSound(fileUrl) {
      const soundEle = document.getElementById("audio");
      soundEle.src = fileUrl;
      soundEle.volume = 1;

      const promise = soundEle.play(); // play()는 promise 객체 반환
      if (promise !== undefined) {
        // promise에 저장된 결과 상태에 따라 하단 분기 실행
        promise
          .then(() => {
            // soundEle.play();가 재생할 준비가 되었으면
            // Autoplay started!
            soundEle.pause();
            soundEle.play(); // 미디어 재생
          })
          .catch(() => {
            // soundEle.play();가 되지 않았으면
            // Autoplay was prevented

            soundEle.pause();
            soundEle.play(); // 미디어 재생
          });

        // 재생 종료 시 실행될 이벤트 리스너 추가
        soundEle.onended = () => {
          if (this.llamonCall) {
            console.log("Audio playback finished.");
            soundEle.volume = 0;
            EventBus.$emit("demoEventPopupYn", true);
            this.llamonCall = false;
            // 오디오 재생이 끝난 후 원하는 작업을 여기에 추가
          } else {
            console.log("this.llamonCall = ", this.llamonCall);
          }
        };
      }

      // 데모 돌발 이벤트 발생 시 스킵 설정되게 이벤트 버스 호출
      // EventBus.$emit("demoEventPopupYn", false);

      // const audio = new Audio(fileUrl);
      // audio.play()
      //   .then(() => {
      //     console.log('Audio is playing');
      //     EventBus.$emit("demoEventPopupYn", true);
      //   })
      //   .catch(error => {
      //     console.error('Error playing audio:', error);
      //   });
    },
    // settingBar 컴포넌트를 재생성 해주기 위한 메소드
    settingBarRefresh() {
      if (this.settingBarKey > 10) {
        // 카운트 리셋 ( 계속 커지는걸 방지하기 위해 )
        this.settingBarKey = 0;
      }
      this.settingBarKey += 1;
    },
    openPopup: function (val) {
      // if (!bEventDemoData) {
      //   this.openModal = val;
      // }
      this.openModal = val;
    },
    backMain() {
      this.$router.push({ name: "event" });
    },
    setCurrentTime() {
      // const week = ["일", "월", "화", "수", "목", "금", "토"];
      var date = new Date();
      // var nextTime = new Date();

      // var year = date.getFullYear();
      // var month = date.getMonth() + 1;
      // var day = date.getDate();
      // var Weekday = date.getDay();
      // var hour = date.getHours();
      // var minute = date.getMinutes();
      // var second = date.getSeconds();

      // month = month < 10 ? "0" + month : month;
      // day = day > 9 ? day : "0" + day;
      // hour = hour > 9 ? hour : "0" + hour;
      // minute = minute > 9 ? minute : "0" + minute;
      // second = second > 9 ? second : "0" + second;

      // nextTime.setSeconds(date.getSeconds() + 1, 0);

      // this.currentDate =
      //   year + "-" + month + "-" + day + "(" + week[Weekday] + ")";
      // this.currentTime = hour + ":" + minute + ":" + second;
      // this.timer = setTimeout(this.setCurrentTime, nextTime - date);

      // 서부 인도네시아 시간대로 변경
      const options = {
        // timeZone: 'Asia/Jakarta', // Jakarta는 WIB 시간대에 속함
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // 24시간 형식
      };

      if (this.$i18n.locale == "ko") {
        this.timerVersion = "ko-KR";
      } else if (this.$i18n.locale == "id") {
        this.timerVersion = "id-ID";
      } else {
        this.timerVersion = "en-US";
      }

      // Intl.DateTimeFormat을 사용하여 포맷팅
      const intlFormatter = new Intl.DateTimeFormat(this.timerVersion, options);
      const formattedParts = intlFormatter.formatToParts(date);

      // 요일만 영어로 포맷팅
      const weekdayFormatter = new Intl.DateTimeFormat(
        this.timerVersion,
        options
      );
      const weekdayFormattedParts = weekdayFormatter.formatToParts(date);
      const weekdayPart = weekdayFormattedParts.find(
        (part) => part.type === "weekday"
      );
      const enWeekday = weekdayPart.value;

      // 시, 분, 초를 개별적으로 추출
      let year, month, day, weekday, hours, minutes, seconds, dayPeriod;
      formattedParts.forEach((part) => {
        if (part.type === "year") {
          year = part.value;
        } else if (part.type === "month") {
          month = part.value;
        } else if (part.type === "day") {
          day = part.value;
        } else if (part.type === "weekday") {
          weekday = part.value;
        } else if (part.type === "hour") {
          hours = part.value;
        } else if (part.type === "minute") {
          minutes = part.value;
        } else if (part.type === "second") {
          seconds = part.value;
        } else if (part.type === "dayPeriod") {
          dayPeriod = part.value; // AM 또는 PM
        }
      });

      this.currentDate = `${year}-${month}-${day} (${enWeekday})`;

      // Intl.DateTimeFormat을 사용하여 포맷팅
      this.currentTime =
        hours + ":" + minutes + ":" + seconds + " " + dayPeriod;
      this.timer = setTimeout(this.setCurrentTime, 1000);
    },

    /* tunnelClickEvent(i_tunnel_info) {
        this.tunnel_info = i_tunnel_info;
        let split_code = i_tunnel_info.link_codes[0].split('-');
        this.tunnel_code = split_code[2];

        if ('R' === this.tunnel_info.tech) {
          this.radar_view = true;
          this.cctv_view = false;
        } else {
          this.radar_view = false;
          this.cctv_view = true;
        }
        EventBus.$emit('mainToRecordDataLinkCode', this.tunnel_info.link_codes);
      } */

    // 음성 인식 시작 또는 중지 토글
    toggleRecognition() {
      if (this.isRecording) {
        this.stopRecognition();
      } else {
        this.startRecognition();
      }
    },

    // 음성 인식을 시작하는 메서드
    startRecognition() {
      if (!this.recognition) {
        // SpeechRecognition 초기화
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        this.recognition = new SpeechRecognition();

        // 연속 인식 설정
        // this.recognition.continuous = true; // 기본값: continuous = false, 음성이 끝나면 자동 종료
        this.recognition.continuous = true;
        this.recognition.lang = "en-US";

        // 음성 인식 결과 처리
        this.recognition.onresult = (event) => {
          const lastResultIndex = event.results.length - 1;
          this.transcript = event.results[lastResultIndex][0].transcript;
          // console.log("음성 인식 결과 처리 ::: ",this.transcript);
        };

        // 음성 인식 시작 시 실행
        this.recognition.onstart = () => {
          this.isListening = true;
          this.isRecording = true; // 아이콘 색상 변경
        };

        // 음성 인식 중지 시 실행
        this.recognition.onend = () => {
          this.isListening = false;
          this.isRecording = false; // 아이콘 색상 변경

          // LLaMon API 호출 (onend에서만 호출)
          // if (this.transcript) {
            console.log(
              "LLaMon API 호출 음성 인식 결과값 ::: ",
              this.transcript
            );
            this.callLLaMon(this.transcript);
          // }

          // 음성 인식이 끝나면 transcript 초기화
          this.transcript = "";
        };
      }

      // 인식 실패(nomatch) 처리
      this.recognition.onnomatch = () => {
        console.log("Speech not recognized, but no alert will show.");
      };

      // 에러 처리
      this.recognition.onerror = (event) => {
        console.error("Speech recognition error detected: " + event.error);
      };

      // 음성 인식 시작
      this.recognition.start();
    },

    // 음성 인식을 중지하는 메서드
    stopRecognition() {
      if (this.recognition) {
        this.recognition.stop();
      }
    },

    async callLLaMon(recordingText) {
      this.loader = true;
      try {
        const res = await this.api.callLLaMon({
          recordingText: recordingText,
        });
        // console.log("res:::",res);
        if (res.status == 200) {
          this.loader = false;
        }
      } catch (err) {
        console.error("Error calling callLLaMon:", err);
      }
    },
  },
  created() {
    //상단 터널명(대시보드)
    EventBus.$on("selectTunnelName", (val) => {
      this.selectTunnelName = val;
    });
  },
  mounted() {
    EventBus.$on("speedColorSetting", (stagnation, delay, slow, smooth) => {
      this.stagnation = Number(stagnation);
      this.delay = Number(delay);
      this.slow = Number(slow);
      this.smooth = Number(smooth);
    });
    this.setCurrentTime();

    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      if ("event_modal" === modalId) {
        // EventBus.$emit('eventModalShow');
      } else if ("chart_modal" === modalId) {
        // EventBus.$emit('chartModalShow', me.tunnel_code);
      } else {
      }
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if ("event_modal" === modalId) {
        // EventBus.$emit('eventModalHide');
      } else if ("chart_modal" === modalId) {
        // EventBus.$emit('chartModalHide');
      } else {
      }
    });
    // EventBus.$emit('storage',this.$storage.data);
    this.socket.addListener(this, "eventDemoData", (data) => {
      console.log("========= LLaMon Call =========", data.type);
      EventBus.$emit("demoEventPopupYn", false);
      this.llamonCall = true;
      // 화살표 함수 사용
      switch (data.type) {
        case 0:
          //this.playSound("/resources/video/demo_type0.mp3");
          alert("Not recognized. Please say that again.");
          EventBus.$emit("demoEventPopupYn", true);
          break;
        case 1:
          this.showDemoPopup();
          break;
        case 2:
          this.playSound("/resources/video/demo_type2.mp3");
          break;
        default:
          alert("Not recognized. Please say that again.");
          EventBus.$emit("demoEventPopupYn", true);
          this.llamonCall = false;
          break;
      }
    });
  },
  beforeDestroy() {
    // EventBus.$off('eventModalShow');
    // EventBus.$off('eventModalHide');
    // EventBus.$off('chartModalShow');
    // EventBus.$off('chartModalHide');
    EventBus.$off("speedColorSetting");
    EventBus.$off("selectTunnelName");
  },
};
// faLibrary.add(faHome,faChevronDown ,faSearch)
// Vue.component('font-awesome-icon', FontAwesomeIcon)
</script>

<style lang="scss" scoped>
/* 대시보드 기본 화면 */
.dashboard_common_panel {
  background-color: #1d2233;
  border: 0;
  border-radius: 0;
  margin-bottom: 0px;
  height: 100%;
}
.dashboard_common_header {
}
.dashboard_common_body {
  background-color: rgb(44, 44, 44);
  border: 0;
  width: 100%;
  height: 100%;
}
.dashboard_common_footer {
  background-color: #1d2233;
  //border-top: 1px solid #1f3a68;
  padding-top: 0px;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: inherit;
}
/* 대시보드 밝은색 컴포넌트 */
.dashboard_primary {
  background-color: #3e64ff !important;
  color: white;
}
/* 대시보드 어두운색 컴포넌트*/
.dashboard_dark {
  border: 0;
  background-color: #282e40;
  /* border: 1px solid #4B5471; */
}
.dashboard_dropdown {
  border: 1px solid #4b5471;
  background-color: #1d2233 !important;
}
.dashboard_dropdown:hover {
  border: 1px solid #4b5471 !important;
}
.dashboard_dropdown:active {
  border: 1px solid #4b5471 !important;
}
.dashboard_input input {
  border: 1px solid #4b5471;
  background-color: #1d2233;
}
.dashboard_input .input-group-append {
  background-color: #1d2233;
  height: calc(1.5em + 1.25rem + 2px);
  font-size: 0.875rem;
}
.dashboard_input .input-group-append:active {
  background-color: #1d2233;
}
.dashboard_input .input-group-append .input-group-text {
  border: 0;
  border: solid 1px #4b5471;
  background-color: #1d2233 !important;
}
.dashboard_input .input-group-append .input-group-text:hover {
  cursor: pointer;
  color: #d7dce0;
}
.dashboard_input input:focus {
  background: #1d2233;
}

.dashboard_input input:focus + .input-group-append {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background: #1d2233;
}
.dashboard_date_picker {
  background-color: #1d2233;
  margin-bottom: 0px;
}
.dashboard_date_picker input {
  border: 1px solid #4b5471;
  border-color: #4b5471 !important;
  background-color: #1d2233;
}
.dashboard_date_picker .input-group-append {
  background-color: #1d2233;
  font-size: 0.875rem;
}
.dashboard_date_picker .input-group-append:active {
  background-color: #1d2233;
  cursor: pointer;
}
.dashboard_date_picker .input-group-append .input-group-text {
  border: 0;
  border: solid 1px #4b5471;
  background-color: #1d2233 !important;
}
.dashboard_date_picker .input-group-append .input-group-text:hover {
  cursor: pointer;
  color: #d7dce0;
}
.dashboard_date_picker input:focus {
  border: solid 1px #4b5471;
  background: #1d2233;
}
.dashboard_date_picker input:focus + .input-group-append {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background: #1d2233;
}
.dashboard_form_date_picker .input-group-append {
  height: calc(1.5em + 1.25rem - 1px) !important;
  font-size: 0.875rem;
}
.dashboard_dropdown_small {
  background-color: rgba(255, 255, 255, 0) !important;
}
.dashboard_dark input {
  border: 0;
  background-color: #282e40;
}
.dashboard_modal input:focus {
  border: 1px solid #8596b2;
}
.dashboard_dark:hover {
  background-color: #3e64ff;
}
.dashboard_dark:active {
  background-color: #3e64ff !important;
}
.dashboard_dark .input_group_append {
  background-color: #282e40;
}
.dashboard_dark .input_group_append:active {
  background-color: #282e40;
}
.dashboard_dark .input-group-append .input-group-text {
  border: 0;
  background-color: #282e40 !important;
}
.dashboard_modal .dashboard_dark .input-group-append .input-group-text {
  border: 1px solid #8596b2;
  background-color: #282e40 !important;
}
.show > .dashboard_dark.dropdown-toggle {
  background-color: #3e64ff;
}
.dashboard_dark input:focus {
  border: solid 1px #8596b2;
  background: #282e40;
}
.dashboard_dark input:focus + .input-group-append {
  border: solid 1px #8596b2;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background: #282e40;
}
.dashboard_modal .dashboard_dark input:focus + .input-group-append {
  border: 0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background: #282e40;
}
/* 대시보드 배경색 */
.dashboard_bgcolor {
  background-color: #1d2233;
}
/* 대시보드 카드색 */
.dashboard_card_bgcolor {
  background-color: #282e40;
}
/* 대시보드 버튼 */
.dashboard_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 버튼안에 아이콘 추가시 */
.dashboard_btn_icon_front {
  margin-right: 28px;
}
.dashboard_btn_icon_back {
  margin-left: 28px;
}
table tbody tr:hover {
  /* border: 1px solid white; */
  background-color: #282e40;
}
.dashboard_modal {
  background-color: #282e40;
}
.dashboard_modal .card-header,
.dashboard_modal h3 {
  background-color: #282e40;
  color: #dddfe4;
}
.dashboard_modal .card-body {
  background-color: #282e40;
}
.dashboard_modal .card-footer {
  background-color: #282e40;
}
.dashboard_modal .card-body input {
  background-color: #282e40;
}
.dashboard_modal .card-body button {
  color: #dddfe4;
  border: 1px solid #8596b2;
  background-color: #282e40;
}
.dropdown-toggle::after {
  border: 0;
}
.dashboard_modal .form-group {
  margin-bottom: 12px;
}
.dashboard_modal input,
button {
  height: 3.5rem;
  border: solid 1px #8596b2;
}
.dashboard_modal .card-body {
  padding-top: 0px;
}
.dashboard_modal .card {
  padding-bottom: 0px !important;
}
.btn:hover {
  color: white;
}
/* 테이블 헤더 */
.table .thead-dark th {
  background-color: #1d2233;
}
.card .table td {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  line-height: 4rem;
}
.dashboard_table_center td {
  text-align: center;
}
.table_header_font {
  font: 13px;
}
.table_body_font {
  font: 12px;
}
.card .table th {
  text-align: center;
}
.dashboard-sidebar-bg {
  background-color: #1b1c2b;
}
//대시보드 트리 스타일
li.dashboard_tree_li::marker {
  color: blue;
}
li.dashboard_tree_li::before {
}
li.dashboard_tree_li::after {
  border-top: 10px solid #999;
  width: 25px;
}
.dashboard_input {
  height: 48px;
}
// dropdown
.dropdown-menu {
  min-width: 0px;
  width: 100%;
}
.dashboard_table {
  margin-top: 3px;
}
.dashboard_table td {
  border-top: 0px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.dashboard_table th {
  border-top: 0px;
}
.modal {
  height: auto;
}
.modal-dialog .modal-dialog-scrollable {
  max-width: 600px !important;
}
.modal.show .modal-dialog {
  overflow-y: hidden;
  max-width: 600px;
}
.card-header:first-child {
  border-radius: 0px;
}
// 포커스 해제시
.focused .form-control {
  border-color: #8596b2;
}
// 드랍 다운 버튼 색상
.dropdown-menu {
  background-color: #282e40;
  border: 1px solid #8596b2;
}
// 드랍 다운 폰트 컬러
.dropdown-menu a {
  color: #8596b2;
  background-color: #282e40 !important;
}

.dropdown-menu a:hover {
  color: rgb(245, 245, 245);
  background-color: #2f364a !important;
}
.dashboard_table th {
  width: 10%;
}

.dashboard_custom_btn {
  color: white !important;
  background-color: #5a7efe !important;
}
// ----- 메시지 박스 -----
.dashboard_msg_box {
  z-index: 2000;
}

.dashboard_msg_box_header {
  background-color: #282e40;
  color: #ffffff;
  padding: 1rem 1.25rem;
}
.dashboard_msg_box_header .modal-title {
  color: #ffffff;
  font-size: 1.3rem;
}
.dashboard_msg_box_body {
  background-color: #282e40;
  color: #ffffff;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  text-align: center;
  white-space: pre;
}
.dashboard_msg_box_footer {
  justify-content: center;
  background-color: #282e40;
  color: #ffffff;
  padding: 1rem 1.5rem;
}
.dashboard_msg_box_footer button {
  font-size: 1.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.dashboard_msg_box_footer .btn-secondary {
  background-color: #282e40 !important;
  border: 1px solid rgb(133, 150, 178) !important;
  color: white;
}
.dashboard_msg_box_footer .btn-primary {
  background-color: #5a7efe !important;
}
// ----- 메시지 박스 -----
// ----- 데이트 픽커 -----
.flatpickr-monthDropdown-months {
  font-size: 1.3rem !important;
}
.flatpickr-weekday {
  font-size: 1.4rem !important;
  color: #c8cdd6 !important;
}
.flatpickr-current-month input.cur-year {
  font-size: 1.4rem !important;
  color: #c8cdd6 !important;
}
.flatpickr-current-month input.cur-year:hover {
  background-color: rgba(192, 187, 167, 0.05) !important;
}
.flatpickr-current-month .numInputWrapper {
  width: 7ch !important;
}
</style>

<style>
.setting-button {
  position: absolute;
  right: 1.6rem;
  top: 0.6rem;
}
.current-time {
  color: white;
  position: absolute;
  right: 4.5rem;
  top: 1rem;
  font-size: 0.875rem;
  text-align: center;
}
.alarm-container {
  position: absolute;
  /* width: 30%;
  left: 35%; */
  /* top : 50%; */
  /* top: 48%;
  height: 16%; */

  width: 40%;
  left: 30%;
  /* top: 48%; */
  top: 43%;
  height: 16%;
}
.back-custom {
  position: absolute;
  left: 1.5rem;
  top: 0.4rem;
  width: 100px;
  height: 4%;
  border-radius: 1.5rem;
}
.seoul-logo {
  position: absolute;
  left: 50%;
  /* top: 0.4rem; */
  transform: translateX(-50%);
  /* width: 220px; */
  height: 4%;
  border-radius: 1.5rem;
}
.text {
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
}
.text_red {
  color: #e64359;
}
.text_orange {
  color: #ff8e43;
}
.text_yellow {
  color: #ffb431;
}
.text_green {
  color: #2ce999;
}
.square {
  font-size: 1.7em;
  /* border: #E64359 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 100px;
  margin-right: 150px;
  box-shadow: 0px 3px 6px #282e40;
}
.demoBtn {
  z-index: 999;
  font-size: 1.7em;
  display: inline-block; /* 버튼 영역을 블록으로 처리 */
  /* border: #E64359 1px solid; */
  margin-top: 100px;
  box-shadow: 0px 3px 6px #282e40;
  justify-content: flex-end;
}
.square img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.vertical-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  border-left: 1px solid white;
  z-index: 2;
}

.recording-icon {
  animation: blink 1s infinite; /* 깜빡임 애니메이션 */
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

button {
  margin-right: 10px;
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
</style>
